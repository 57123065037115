import React from 'react'

import { LessonListItemSkeleton } from './LessonListItemSkeleton'

export const LessonListPageLoading: React.FC = () => (
  <div>
    {[...Array(12)].map((x, i) => (
      <LessonListItemSkeleton key={i} />
    ))}
  </div>
)
