import { LinkProps } from 'next/link'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Container } from '@/components/_layout/Container'
import { ContainerError } from '@/components/_layout/ContainerMessage'
import {
  Dropdown,
  IDropdownOption,
  IItemRendererProps,
} from '@/components/_shared/Dropdown'
import { NextLink } from '@/components/_shared/NextLink'
import { StyledH1 } from '@/components/lesson/lesson/style'
import { useLessonFiltersState } from '@/contexts/lessonFilters'
import { COOKIE_NAME, useCookie } from '@/hooks/use-cookie'
import { usePropertyValueSharedResults } from '@/hooks/use-property'
import { IAreaKey, IPropertyValueArea } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'
import { buildLessonExploreRoute } from '@/utils/route'

import { LessonAreaListSentenceDropdownLoading } from './loaders/LessonAreaListSentenceDropdownLoading'

interface IProps {
  buildRoute?: (areaSlug: string) => LinkProps['href']
}

export const LessonAreaListSentenceDropdown: React.FC<IProps> = ({
  buildRoute = buildLessonExploreRoute,
}) => {
  const { lessonFiltersState } = useLessonFiltersState()
  const {
    propValueListLoading: areaListLoading,
    propValueList: areaList,
    propValueListError: areaListError,
  } = usePropertyValueSharedResults('area')
  const { updateCookie: updateCookieAreaKey } = useCookie<IAreaKey>(
    COOKIE_NAME.AREA_SELECTED
  )

  if (areaListLoading) return <LessonAreaListSentenceDropdownLoading />
  if (areaListError) return <ContainerError />

  return (
    <Container>
      <FormattedMessage
        tagName={StyledH1}
        defaultMessage="Trouvez votre cours en studio à {dropdown}"
        description="LessonAreaListSentenceDropdown: title"
        values={{
          dropdown: (
            <StyledDropdown
              options={buildAreaListOptions(areaList, buildRoute)}
              selectedValue={lessonFiltersState.areaKey}
              ItemRenderer={ItemRenderer}
              onOptionSelectFunc={(areaOption: IDropdownOptionArea) => {
                updateCookieAreaKey(areaOption.value)
              }}
            />
          ),
        }}
      />
    </Container>
  )
}

export interface IDropdownOptionArea extends IDropdownOption {
  value: IAreaKey
  href: LinkProps['href']
  area: IPropertyValueArea
}

export const buildAreaListOptions = (
  areaList: IPropertyValueArea[],
  buildRoute: IProps['buildRoute']
): IDropdownOptionArea[] =>
  areaList.map((area) => ({
    label: area.title,
    value: area.slug as IAreaKey,
    href: buildRoute(area.slug),
    area,
  }))

const ItemRenderer: React.FC<IItemRendererProps<IDropdownOptionArea>> = ({
  option,
  onClick,
}) => (
  <StyledNextLink href={option.href} onClick={onClick}>
    {option.label}
  </StyledNextLink>
)

const StyledDropdown = styled(Dropdown)`
  && {
    color: ${({ theme }) => theme.color.terracota};
    display: inline-flex;

    svg {
      height: ${({ theme }) => theme.spacing.s};
      margin-top: ${({ theme }) => theme.spacing.xxs};
      stroke-width: 1;
      width: ${({ theme }) => theme.spacing.s};

      @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
        height: ${({ theme }) => theme.spacing.m};
        margin-left: ${({ theme }) => theme.spacing.s};
        margin-top: ${({ theme }) => theme.spacing.s};
        width: ${({ theme }) => theme.spacing.m};
      }
    }
  }
`
const StyledNextLink = styled(NextLink)`
  ${({ theme }) => theme.typo.h3}

  color: ${({ theme }) => theme.color.black};
  display: block;
  padding: ${({ theme }) => theme.spacing.s};

  &:not(:last-child) {
    padding-bottom: 0;
  }

  &:hover {
    text-decoration: none;
  }
`
