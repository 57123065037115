import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

import { DEVICE_WIDTH } from '@/utils/constants'

const LINE_WIDTH = 160
const LINE_HEIGHT = 20
const MARGIN = 8

export const LessonListItemSkeletonMobile: React.FC = () => (
  <Wrapper>
    <StyledContentLoader uniqueKey="LessonListItemSkeletonMobile">
      <rect
        x="0"
        y="0"
        rx={LINE_HEIGHT / 2}
        ry={LINE_HEIGHT / 2}
        width={LINE_WIDTH}
        height={LINE_HEIGHT}
      />
      <rect
        x="0"
        y={LINE_HEIGHT + MARGIN}
        rx={LINE_HEIGHT / 2}
        ry={LINE_HEIGHT / 2}
        width={LINE_WIDTH / 2}
        height={LINE_HEIGHT}
      />
      <rect
        x={LINE_WIDTH / 2 + MARGIN}
        y={LINE_HEIGHT + MARGIN}
        rx={LINE_HEIGHT / 2}
        ry={LINE_HEIGHT / 2}
        width={LINE_WIDTH}
        height={LINE_HEIGHT}
      />
      <rect
        x="0"
        y={(LINE_HEIGHT + MARGIN) * 2}
        rx={LINE_HEIGHT / 2}
        ry={LINE_HEIGHT / 2}
        width={LINE_WIDTH / 2}
        height={LINE_HEIGHT}
      />
      <rect
        x="0"
        y={(LINE_HEIGHT + MARGIN) * 3}
        rx={LINE_HEIGHT / 2}
        ry={LINE_HEIGHT / 2}
        width={LINE_WIDTH / 2}
        height={LINE_HEIGHT}
      />
    </StyledContentLoader>
  </Wrapper>
)

const Wrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.greyLight};
  padding-bottom: ${({ theme }) => theme.spacing.s};
  padding-top: ${({ theme }) => theme.spacing.s};
  width: 100%;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: none;
  }
`
const StyledContentLoader = styled(ContentLoader)`
  height: ${LINE_HEIGHT * 4 + MARGIN * 3}px;
  width: 100%;
`
