import React, { useEffect, useState } from 'react'
import { theme } from 'theme'

import { useMap } from '@/contexts/mapContext'
import { useMapItems } from '@/contexts/mapItemsContext'
import { ILessonDecorated, IPlaceDecorated } from '@/types/main'

export type IMapItem = ILessonDecorated | IPlaceDecorated

interface IProps {
  item: IMapItem
}

export interface IMarker extends google.maps.Marker {
  item: IMapItem
}

export const markerIconStyle = {
  scale: 15,
  fillColor: theme.color.terracota,
  fillOpacity: 1,
  strokeColor: theme.color.terracota,
  strokeOpacity: 0.5,
  strokeWeight: 10,
}

export const markerLabel = {
  text: '1',
  color: theme.color.white,
  fontFamily: 'Inter Regular',
  fontSize: '14px',
}

export const ItemsMapMarker: React.FC<IProps> = (options) => {
  const [marker, setMarker] = useState<IMarker>()
  const [
    {
      mapState: { markers },
    },
    dispatchMap,
  ] = useMap()
  const [
    {
      mapItemsState: { hoveredMapItem },
    },
    dispatchMapItems,
  ] = useMapItems()
  const { item } = options

  const icon: google.maps.Symbol = {
    path: google.maps.SymbolPath.CIRCLE,
    ...markerIconStyle,
  }

  // create marker and clean it if component unmount
  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker() as IMarker)
    }

    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [marker])

  // add marker to list of markers
  useEffect(() => {
    if (!marker) {
      return
    }

    if (!markers.includes(marker)) {
      const place = isILessonDecorated(item) ? item.place : item

      marker.setOptions({
        icon,
        label: markerLabel,
        title: `${item.title} - ${place.city}`,
        position: {
          lat: +place.latitude,
          lng: +place.longitude,
        },
        ...options,
      })

      marker.addListener('click', () => {
        dispatchMapItems({
          type: 'setSelectedMapItems',
          selectedMapItems: [item],
        })
      })

      dispatchMap({ type: 'addMarker', marker })
    }
  }, [marker, options, markers, hoveredMapItem])

  // style marker according to hoveredMapLesson
  useEffect(() => {
    setOnHoverMarkerBehavior(marker, item?.id === hoveredMapItem?.id)
  }, [marker, hoveredMapItem])

  return null
}

const modifyMarkerColor = (marker: IMarker, color: string) => {
  return marker?.setIcon({
    ...markerIconStyle,
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: color,
    strokeColor: color,
  })
}

export const setOnHoverMarkerBehavior = (
  marker: IMarker,
  isHoveredMapLesson: boolean
) => {
  if (!marker) {
    return
  }

  modifyMarkerColor(
    marker,
    isHoveredMapLesson ? theme.color.mandarine : theme.color.terracota
  )

  const mouseOverListener = marker.addListener('mouseover', () => {
    modifyMarkerColor(marker, theme.color.mandarine)
  })

  const mouseOutListener = marker.addListener('mouseout', () => {
    modifyMarkerColor(marker, theme.color.terracota)
  })

  // clear listener on component unmount
  return () => {
    google.maps.event.removeListener(mouseOverListener)
    google.maps.event.removeListener(mouseOutListener)
  }
}

const isILessonDecorated = (item: IMapItem): item is ILessonDecorated =>
  Object.keys(item).includes('place')
