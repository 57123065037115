import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

import { Container } from '@/components/_layout/Container'
import { DEVICE_WIDTH } from '@/utils/constants'

const HEIGHT = 69

export const LessonAreaListSentenceDropdownLoading: React.FC = () => (
  <StyledContainer>
    <StyledContentLoader uniqueKey="LessonAreaListSentenceDropdownLoading">
      <rect x="0" y="0" rx={HEIGHT / 2} ry={HEIGHT / 2} width="100%" height={HEIGHT} />
    </StyledContentLoader>
  </StyledContainer>
)

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing.ms} 0;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin: ${({ theme }) => theme.spacing.l} 0;
  }
`
const StyledContentLoader = styled(ContentLoader)`
  height: ${HEIGHT}px;
  width: 70%;
`
