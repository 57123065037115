import { ILessonDecorated } from '@/types/main'

export type IMapState = {
  selectedLessons: ILessonDecorated[]
  hoveredLesson: ILessonDecorated | null
  map: google.maps.Map
  markers: google.maps.Marker[]
}

export const initialMapState: IMapState = {
  selectedLessons: [],
  hoveredLesson: null,
  map: null,
  markers: [],
}

export type IMapAction =
  | { type: 'setMap'; map: google.maps.Map }
  | { type: 'setMarkers'; markers: google.maps.Marker[] }
  | { type: 'addMarker'; marker: google.maps.Marker }
  | { type: 'resetMarkers' }

export type IMapDispatch = (action: IMapAction) => void

export const mapReducer = (state: IMapState, action: IMapAction) => {
  switch (action.type) {
    case 'setMap':
      return { ...state, map: action.map }

    case 'setMarkers':
      return { ...state, markers: action.markers }

    case 'addMarker':
      return { ...state, markers: [...state.markers, action.marker] }

    case 'resetMarkers':
      return { ...state, markers: [] }

    default:
      return state
  }
}
