import React, { useRef } from 'react'
import styled from 'styled-components'

import { IMapItem } from '@/components/_shared/map/ItemsMapMarker'
import { useMapItems } from '@/contexts/mapItemsContext'
import { IMapItemsDispatch } from '@/reducers/mapItemsReducer'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  item: IMapItem
  className?: string
}

export const MapListItemWrapper: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  item,
  className,
}) => {
  const itemRef = useRef<HTMLDivElement>()
  const [
    {
      mapItemsState: { hoveredMapItem },
    },
    dispatchMapItems,
  ] = useMapItems()

  return (
    <Wrapper
      className={className}
      ref={itemRef}
      onMouseEnter={() => onMouseEnterMapElement(item, dispatchMapItems)}
      onMouseLeave={() => onMouseLeaveMapElement(hoveredMapItem, dispatchMapItems)}
    >
      {children}
    </Wrapper>
  )
}

const onMouseEnterMapElement = (item: IMapItem, dispatchMapItems: IMapItemsDispatch) => {
  dispatchMapItems({ type: 'setHoveredMapItem', hoveredMapItem: item })
}

const onMouseLeaveMapElement = (
  hoveredMapLesson: IMapItem,
  dispatchMapLessons: IMapItemsDispatch
) => {
  if (!hoveredMapLesson) {
    return
  }

  dispatchMapLessons({ type: 'resetHoveredMapItem' })
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.spacing.s};
  box-shadow: ${({ theme }) => theme.boxShadow.neutral};
  display: flex;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing.ms};
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.color.beigeLight};
  }

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    align-items: center;
    border-top: 1px solid ${({ theme }) => theme.color.greyLighter};
    gap: ${({ theme }) => theme.spacing.s};
  }
`
