import { ILessonDecorated, IPlaceDecorated } from '@/types/main'

export type IGenericMapItem = IPlaceDecorated | ILessonDecorated

export type IMapItemsState<T = IGenericMapItem> = {
  selectedMapItems: T[]
  hoveredMapItem: T | null
}

export const initialMapItemsState: IMapItemsState = {
  selectedMapItems: [],
  hoveredMapItem: null,
}

export type IMapItemsAction<T = IGenericMapItem> =
  | {
      type: 'setSelectedMapItems'
      selectedMapItems: T[]
    }
  | { type: 'resetSelectedMapItems' }
  | { type: 'setHoveredMapItem'; hoveredMapItem: T }
  | { type: 'resetHoveredMapItem' }

export type IMapItemsDispatch<T = IGenericMapItem> = (action: IMapItemsAction<T>) => void

export const mapItemsReducer = (state: IMapItemsState, action: IMapItemsAction) => {
  switch (action.type) {
    case 'resetSelectedMapItems':
      return { ...state, selectedMapItems: initialMapItemsState.selectedMapItems }

    case 'setSelectedMapItems':
      return { ...state, selectedMapItems: action.selectedMapItems }

    case 'resetHoveredMapItem':
      return { ...state, hoveredMapItem: initialMapItemsState.hoveredMapItem }

    case 'setHoveredMapItem':
      return { ...state, hoveredMapItem: action.hoveredMapItem }

    default:
      return state
  }
}
