import { createContext, useContext, useReducer } from 'react'

import { IMapDispatch, IMapState, mapReducer } from '@/reducers/mapReducer'

export interface IMapStateContext {
  mapState: IMapState
  initialMapState: IMapState
}

export const MapStateContext = createContext<IMapStateContext | undefined>(undefined)

export const MapDispatchContext = createContext<IMapDispatch | undefined>(undefined)

interface IProps {
  initialMapState: IMapState
}

export const MapContextProvider: React.FC<React.PropsWithChildren<IProps>> = ({ children, initialMapState }) => {
  const [mapState, mapDispatch] = useReducer(mapReducer, initialMapState)

  return (
    <MapStateContext.Provider value={{ mapState, initialMapState }}>
      <MapDispatchContext.Provider value={mapDispatch}>
        {children}
      </MapDispatchContext.Provider>
    </MapStateContext.Provider>
  )
}

export const useMapState = (): IMapStateContext => {
  const context = useContext(MapStateContext)

  if (context === undefined) {
    throw new Error('useMapState must be called inside a subtree of MapContextProvider')
  }

  return context
}

export const useMapDispatch = (): IMapDispatch => {
  const context = useContext(MapDispatchContext)

  if (context === undefined) {
    throw new Error(
      'useMapDispatch must be called inside a subtree of MapContextProvider'
    )
  }

  return context
}

export const useMap = (): [IMapStateContext, IMapDispatch] => [
  useMapState(),
  useMapDispatch(),
]
