import { KeyedMutator, SWRConfiguration } from 'swr'

import {
  getLessonOnsiteResults,
  ILessonDecoratedGQLResults,
  ILessonGQLResults,
  ILessonResultsVariables,
} from '@/services/api-graphql-lesson'
import { HTTPError } from '@/utils/api-helpers'
import { decorateLesson } from '@/utils/decorator'
import { buildGenericSwrMutate } from '@/utils/swr'

import { useSWRAuth } from './useSwr-auth'
import { useSWRCustom } from './useSwr-custom'

export const SWR_KEY__LESSON_ONSITE_RESULTS = 'lessonOnsite_results'

interface IReturnType {
  lessonListLoading: boolean
  lessonList: ILessonDecoratedGQLResults
  lessonListError: HTTPError
  lessonListValidating: boolean
  mutateLessonList: KeyedMutator<ILessonGQLResults>
}

export const useSWRLessonOnsiteResults = (
  gqlVariables: ILessonResultsVariables,
  swrConfig: SWRConfiguration = { revalidateOnFocus: false }
): IReturnType => {
  const { payload } = useSWRAuth()
  const { data, error, mutate, isLoading, isValidating } =
    useSWRCustom<ILessonGQLResults>(
      [SWR_KEY__LESSON_ONSITE_RESULTS, payload?.uuid],
      getLessonOnsiteResults,
      gqlVariables,
      swrConfig
    )

  return {
    lessonListLoading: isLoading,
    lessonList: data && decorateLessonList(data, mutate),
    lessonListError: error,
    lessonListValidating: isValidating,
    mutateLessonList: mutate,
  }
}

const decorateLessonList = (
  lessonResults: ILessonGQLResults,
  mutateLessonList: IReturnType['mutateLessonList']
): ILessonDecoratedGQLResults => {
  // Custom lesson.mutate that proxy change on mutateLessonList
  const mutate = buildGenericSwrMutate(mutateLessonList)

  const lessonDecoratedCollection = lessonResults.collection.map((lesson) =>
    decorateLesson(lesson, mutate)
  )

  return {
    ...lessonResults,
    collection: lessonDecoratedCollection,
  }
}
