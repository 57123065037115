import { createContext, useContext, useReducer } from 'react'

import {
  IGenericMapItem,
  IMapItemsDispatch,
  IMapItemsState,
  mapItemsReducer,
} from '@/reducers/mapItemsReducer'

export interface IMapItemsStateContext<T> {
  mapItemsState: IMapItemsState<T>
  initialMapItemsState: IMapItemsState<T>
}

export const MapItemsStateContext = createContext<IMapItemsStateContext<any> | undefined>(
  undefined
)

export const MapItemsDispatchContext = createContext<IMapItemsDispatch<any> | undefined>(
  undefined
)

interface IProps {
  initialMapItemsState: IMapItemsState
}

export const MapItemsContextProvider: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  initialMapItemsState,
}) => {
  const [mapItemsState, mapItemsDispatch] = useReducer(
    mapItemsReducer,
    initialMapItemsState
  )

  return (
    <MapItemsStateContext.Provider value={{ mapItemsState, initialMapItemsState }}>
      <MapItemsDispatchContext.Provider value={mapItemsDispatch}>
        {children}
      </MapItemsDispatchContext.Provider>
    </MapItemsStateContext.Provider>
  )
}

export const useMapItemsState = <T,>(): IMapItemsStateContext<T> => {
  const context = useContext(MapItemsStateContext)

  if (context === undefined) {
    throw new Error(
      'useMapItemsState must be called inside a subtree of MapItemsContextProvider'
    )
  }

  return context
}

export const useMapItemsDispatch = <T,>(): IMapItemsDispatch<T> => {
  const context = useContext(MapItemsDispatchContext)

  if (context === undefined) {
    throw new Error(
      'useMapItemsDispatch must be called inside a subtree of MapItemsContextProvider'
    )
  }

  return context
}

export const useMapItems = <T = IGenericMapItem,>(): [
  IMapItemsStateContext<T>,
  IMapItemsDispatch<T>
] => [useMapItemsState<T>(), useMapItemsDispatch<T>()]
