import { Status, Wrapper } from '@googlemaps/react-wrapper'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { ContainerError, ContainerLoading } from '@/components/_layout/ContainerMessage'
import { ItemsMap } from '@/components/_shared/map/ItemsMap'
import { IMapItem, ItemsMapMarker } from '@/components/_shared/map/ItemsMapMarker'
import { MapContextProvider, useMapDispatch } from '@/contexts/mapContext'
import { useMapItems } from '@/contexts/mapItemsContext'
import { initialMapState } from '@/reducers/mapReducer'
import { Config } from '@/services/config'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  items: IMapItem[]
  className?: string
}

const render = (status: Status) => {
  if (status === Status.FAILURE) return <ContainerError />
  return <ContainerLoading />
}

const MapWrapper: React.FC<React.PropsWithChildren<IProps>> = ({
  items,
  children,
  className,
}) => {
  const dispatchMap = useMapDispatch()
  const [
    {
      mapItemsState: { selectedMapItems },
    },
    dispatchMapItems,
  ] = useMapItems()
  const route = useRouter()

  useEffect(() => {
    dispatchMap({ type: 'resetMarkers' })
    dispatchMapItems({ type: 'resetHoveredMapItem' })
    dispatchMapItems({ type: 'resetSelectedMapItems' })
  }, [route])

  // when items are mutated (ex: with favorite toggler) then update selectedMapItems
  useEffect(() => {
    if (!items?.length || !selectedMapItems?.length) {
      return
    }

    const selectedMapItemsId = selectedMapItems.map((item) => item.id)
    const selectedMapItemsMutated = items.filter((item) =>
      selectedMapItemsId.includes(item.id)
    )

    dispatchMapItems({
      type: 'setSelectedMapItems',
      selectedMapItems: selectedMapItemsMutated,
    })
  }, [items])

  return (
    <Container className={className}>
      <Wrapper apiKey={Config.googleApiKey} render={render}>
        <ItemsMap>
          {items?.map((item: IMapItem) => <ItemsMapMarker key={item.id} item={item} />)}
        </ItemsMap>
      </Wrapper>

      {children}
    </Container>
  )
}

export const MapWrapperInContext: React.FC<React.PropsWithChildren<IProps>> = ({
  items,
  children,
  className,
}) => (
  <MapContextProvider initialMapState={initialMapState}>
    <MapWrapper items={items} className={className}>
      {children}
    </MapWrapper>
  </MapContextProvider>
)

const Container = styled.div`
  align-items: stretch;
  box-shadow: ${({ theme }) => theme.boxShadow.neutral};
  display: flex;
  flex: 4;
  height: 100vh;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    border-radius: ${({ theme }) => theme.spacing.s};
    height: inherit;
  }
`
