import {
  Cluster,
  defaultOnClusterClickHandler,
  MarkerClusterer,
  MarkerClustererOptions,
  SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import {
  IMarker,
  markerIconStyle,
  markerLabel,
  setOnHoverMarkerBehavior,
} from '@/components/_shared/map/ItemsMapMarker'
import { useMap } from '@/contexts/mapContext'
import { useMapItems } from '@/contexts/mapItemsContext'
import { Config } from '@/services/config'

const maxZoom = 18

class CustomMarkerClusterer extends MarkerClusterer {
  constructor(props: MarkerClustererOptions) {
    super(props)
  }

  public getClusters = () => this.clusters
}

interface ICluster extends Cluster {
  markers: IMarker[]
  marker: IMarker
}

export const ItemsMap: React.FC<React.PropsWithChildren> = ({ children }) => {
  const ref = useRef()
  const [markerClusterer, setMarkerClusterer] = useState<CustomMarkerClusterer>()
  const [
    {
      mapState: { map, markers },
    },
    dispatchMap,
  ] = useMap()
  const [
    {
      mapItemsState: { hoveredMapItem, selectedMapItems },
    },
    dispatchMapItems,
  ] = useMapItems()

  // create map
  useEffect(() => {
    dispatchMap({
      type: 'setMap',
      map: new window.google.maps.Map(ref.current, {
        mapId: Config.googleMapsStyleId,
        maxZoom: maxZoom,
        disableDefaultUI: true,
        zoomControl: true,
        zoomControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
      } as google.maps.MapOptions),
    })
  }, [ref])

  // bound map according to markers (defines zoom and center automatically)
  useEffect(() => {
    if (markers?.length <= 0 || !map) {
      markerClusterer?.setMap(null)
      return
    }

    const bounds = new google.maps.LatLngBounds()
    markers.forEach(
      (marker) => marker.getPosition().lat() !== 0 && bounds.extend(marker.getPosition())
    )
    map.fitBounds(bounds)
  }, [markers, map])

  // create markerClusterer
  useEffect(() => {
    setMarkerClusterer(
      new CustomMarkerClusterer({
        markers,
        map,
        renderer: {
          render: (cluster: ICluster) =>
            new google.maps.Marker({
              position: cluster.position,
              label: { ...markerLabel, text: cluster.markers?.length.toString() },
              icon: { ...markerIconStyle, path: google.maps.SymbolPath.CIRCLE },
            }),
        },
        onClusterClick: (
          event: google.maps.MapMouseEvent,
          cluster: ICluster,
          map: google.maps.Map
        ) => {
          defaultOnClusterClickHandler(event, cluster, map)

          if (map.getZoom() === maxZoom) {
            dispatchMapItems({
              type: 'setSelectedMapItems',
              selectedMapItems: cluster.markers.map((marker) => marker.item),
            })
          }
        },
        algorithm: new SuperClusterAlgorithm({ maxZoom }),
      })
    )

    return () => {
      if (markerClusterer) {
        markerClusterer.setMap(null)
      }
    }
  }, [markers, map])

  // modify cluster icon color if cluster includes hoveredMapItem
  useEffect(() => {
    if (!markerClusterer?.getClusters().length) {
      return
    }

    markerClusterer.getClusters().forEach((cluster: ICluster) => {
      setOnHoverMarkerBehavior(
        cluster.marker,
        cluster.markers.some((marker) => marker.item?.id === hoveredMapItem?.id)
      )
    })
  }, [hoveredMapItem, markerClusterer?.getClusters()])

  // onClick map (and not marker) reset selectedMapItems to hide carousel
  useEffect(() => {
    if (map) {
      map.addListener('click', () => {
        if (selectedMapItems) {
          dispatchMapItems({ type: 'resetSelectedMapItems' })
        }
      })

      return () => {
        google.maps.event.clearListeners(map, 'click')
      }
    }
  }, [map])

  return (
    <MapContainer ref={ref} id="map">
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map } as unknown)
        }
      })}
    </MapContainer>
  )
}

const MapContainer = styled.div`
  flex: 1;
`
