import React from 'react'

import { LessonListItemSkeletonDesktop } from './LessonListItemSkeletonDesktop'
import { LessonListItemSkeletonMobile } from './LessonListItemSkeletonMobile'

export const LessonListItemSkeleton: React.FC = () => (
  <>
    <LessonListItemSkeletonDesktop />
    <LessonListItemSkeletonMobile />
  </>
)
