import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

import { DEVICE_WIDTH } from '@/utils/constants'

const LINE_WIDTH = 160
const LINE_HEIGHT = 24
const MARGIN = 8
const BUTTON_HEIGHT = 48
const ITEM_HEIGHT = LINE_HEIGHT * 2 + MARGIN * 3

export const LessonListItemSkeletonDesktop: React.FC = () => (
  <Wrapper>
    <TitleSkeleton />
    <DateSkeleton />
    <PlaceSkeleton />
    <TeacherSkeleton />
    <ButtonSkeleton />
  </Wrapper>
)

const TitleSkeleton: React.FC = () => (
  <StyledContentLoader uniqueKey="TitleSkeleton">
    <rect
      x="0"
      y={MARGIN}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={LINE_WIDTH}
      height={LINE_HEIGHT}
    />
    <rect
      x="0"
      y={LINE_HEIGHT + MARGIN * 2}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={LINE_WIDTH / 2}
      height={LINE_HEIGHT}
    />
  </StyledContentLoader>
)

const DateSkeleton: React.FC = () => (
  <StyledContentLoader uniqueKey="DateSkeleton">
    <rect
      x="0"
      y={MARGIN}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={LINE_WIDTH / 2}
      height={LINE_HEIGHT}
    />
    <rect
      x="0"
      y={LINE_HEIGHT + MARGIN * 2}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={LINE_WIDTH}
      height={LINE_HEIGHT}
    />
  </StyledContentLoader>
)

const PlaceSkeleton: React.FC = () => (
  <StyledContentLoader uniqueKey="PlaceSkeleton">
    <rect
      x="0"
      y={MARGIN}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={LINE_WIDTH / 2}
      height={LINE_HEIGHT}
    />
    <rect
      x="0"
      y={LINE_HEIGHT + MARGIN * 2}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={LINE_WIDTH / 2}
      height={LINE_HEIGHT}
    />
  </StyledContentLoader>
)

const TeacherSkeleton: React.FC = () => (
  <StyledContentLoader uniqueKey="TeacherSkeleton">
    <circle cx={ITEM_HEIGHT / 2} cy={ITEM_HEIGHT / 2} r={ITEM_HEIGHT / 2} />
    <rect
      x={ITEM_HEIGHT + MARGIN}
      y={ITEM_HEIGHT / 2 - LINE_HEIGHT / 2}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={LINE_WIDTH / 2}
      height={LINE_HEIGHT}
    />
  </StyledContentLoader>
)

const ButtonSkeleton: React.FC = () => (
  <StyledContentLoader uniqueKey="ButtonSkeleton">
    <rect
      x="0"
      y={ITEM_HEIGHT / 2 - BUTTON_HEIGHT / 2}
      rx={BUTTON_HEIGHT / 2}
      ry={BUTTON_HEIGHT / 2}
      width="100%"
      height={BUTTON_HEIGHT}
    />
  </StyledContentLoader>
)

const Wrapper = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    align-items: center;
    border-top: 1px solid ${({ theme }) => theme.color.greyLight};
    display: grid;
    gap: ${({ theme }) => theme.spacing.s};
    grid-template-columns: 3fr 2fr 2fr 3fr 2fr;
    padding: ${({ theme }) => theme.spacing.m} ${({ theme }) => theme.spacing.ms};
    width: 100%;
  }
`

const StyledContentLoader = styled(ContentLoader)`
  height: ${ITEM_HEIGHT}px;
  width: 100%;
`
